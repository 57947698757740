<template>
  <footer :class="$style.footer">
    <aw-spring :desktop-size="12">
      <section :class="$style.footerLinks">
        <div v-for="child in footerMenu" :key="child.id">
          <lv-heading level="4" tag="h2" :class="$style.footerLinksHeader">
            {{ child.title }}
          </lv-heading>
          <div :class="$style.footerLinksLine" />
          <ul :class="['ulReset', $style.footerLinksList]">
            <li v-for="grandchild in child.children" :key="grandchild.id">
              <lv-button
                :class="$style.footerLinksListItem"
                v-bind="{[$urlParts(grandchild.link).isOuter ? 'href': 'to']: $urlParts(grandchild.link).link }"
                styling="link-primary"
                :shrink="true"
                :target="grandchild.newTab ? '_blank' : null"
              >
                {{ grandchild.title }}
              </lv-button>
            </li>
          </ul>
        </div>
      </section>
    </aw-spring>
    <div :class="$style.footerContentWrapper">
      <aw-spring :desktop-size="12">
        <section :class="$style.footerContent">
          <div :class="$style.footerContentSocial">
            <div :class="$style.footerContentSocialWrapper">
              <client-only>
                <lv-icon
                  name="auchan-logo"
                  :size="screenRange['desktop-medium-min'] ? 40 : 32"
                  fill
                  :class="[$style.footerContentSocialIcon, $style.footerContentSocialLogo]"
                />
              </client-only>
              <div :class="$style.footerContentSocialLine" />
              <div :class="$style.footerContentSocialWrapperIcons">
                <div v-for="(s, i) in socials" :key="i" :class="$style[`footerContentSocial${s.id}`]">
                  <lv-button
                    :href="$awt(s.href)"
                    styling="link-primary"
                    shrink
                    target="_blank"
                    rel="noreferrer"
                  >
                    <lv-icon
                      :name="s.icon"
                      :size="24"
                      fill
                      :class="[$style.footerContentSocialIcon, $style.footerContentSocialIconSite]"
                    />
                    <span :class="$style.footerContentSocialIconText">
                      {{ $awt(s.text) }}
                    </span>
                  </lv-button>
                </div>
              </div>
            </div>
            <p :class="[$style.footerContentLegal, $style.footerContentLegalDesktop]">
              {{ $awt('aw.footer.legal') }}
            </p>
          </div>
          <div :class="$style.footerContentButtons">
            <div
              v-for="currentElement in buttonElements"
              :key="currentElement.title"
              :class="$style.footerContentButtonsColumn"
            >
              <lv-heading level="4" tag="h3" :class="$style.footerContentButtonsColumnHeader">
                {{ $awt(currentElement.title) }}
              </lv-heading>
              <p :class="$style.footerContentButtonsColumnDescription">
                {{ $awt(currentElement.description) }}
              </p>
              <div :class="$style.footerContentButtonsColumnButtonWrapper">
                <lv-button
                  styling="small-secondary-light"
                  :href="$awt(currentElement.link)"
                  :target="currentElement.newTab ? '_blank' : null"
                >
                  {{ $awt(currentElement.linkTitle) }}
                </lv-button>
              </div>
            </div>
          </div>
          <p :class="[$style.footerContentLegal, $style.footerContentLegalMobile]">
            {{ $awt('aw.footer.legal') }}
          </p>
        </section>
      </aw-spring>
    </div>
  </footer>
</template>

<script>
  import { mapState } from 'pinia';
  import AwSpring from '~~/common/components/AwSpring';
  import { LvButton, LvHeading, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useContentMenusStore } from '~~/common/stores/contentMenus';

  export default {
    name: 'AwFooter',
    components: {
      LvHeading,
      LvButton,
      LvIcon,
      AwSpring,
    },
    data () {
      return {
        socials: [
          {
            id: 'Facebook',
            icon: 'facebook-soft-24',
            href: 'aw.footer.facebook_url',
            text: 'aw.footer.facebook',
          },
          {
            id: 'Instagram',
            icon: 'instagram-soft-24',
            href: 'aw.footer.instagram_url',
            text: 'aw.footer.instagram',
          },
          {
            id: 'YouTube',
            icon: 'youtube-soft-24',
            href: 'aw.footer.youtube_url',
            text: 'aw.footer.youtube',
          },
        ],
        buttonElements: [
          {
            title: 'aw.footer.contact.title',
            description: 'aw.footer.contact.description',
            link: 'aw.footer.contact.link_url',
            linkTitle: 'aw.footer.contact.link_title',
            newTab: false,
          },
          {
            title: 'aw.footer.career.title',
            description: 'aw.footer.career.description',
            link: 'aw.footer.career.link_url',
            linkTitle: 'aw.footer.career.link_title',
            newTab: false,
          },
        ],
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useContentMenusStore, {
        footerMenu: state => state.footerMenu?.children || [],
      }),
    },
    mounted () {
      const contentMenusStore = useContentMenusStore();
      contentMenusStore.initMenu('footer-menu');
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

$footer-border-radius: 32px 32px 0 0;

.footer {
  font-family: $secondary-font;
  border-radius: $footer-border-radius;
  background-color: $color-white;

  &Links {
    display: grid;
    padding: 32px 0;
    border-radius: $footer-border-radius;
    background-color: $color-white;
    column-gap: 40px;
    row-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    @include tablet(min) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
      padding: 80px 0;
    }
    @include desktop-small(min) {
      grid-template-columns: repeat(5, 1fr);
      column-gap: 32px;
      padding: 80px 0;
    }
    @include desktop-medium(min) {
      padding: 80px 0;
      column-gap: 40px;
    }

    &Header {
      font-size: 16px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;
      @include tablet(min) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &Line {
      width: 48px;
      margin: 16px 0;
      border-top: 4px solid $color-brand-primary;
      border-radius: 100px;
      @include tablet(min) {
        margin: 40px 0;
      }
    }

    &List {
      display: flex;
      flex-direction: column;
      gap: 12px;
      @include desktop-small(min) {
        gap: 16px;
      }

      &Item {
        font-size: 12px;
        font-weight: $font-weight-medium-v2;
        line-height: 16px;
        color: $color-text-primary;
        @include tablet(min) {
          font-size: 14px;
          line-height: 20px;
        }

        &:hover {
          color: $color-text-primary;
        }
      }
    }
  }

  &Content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    @include tablet(min) {
      padding: 40px 0;
    }
    @include desktop-small(min) {
      padding: 52px 0;
      grid-template-columns: repeat(2, 1fr);
    }
    @include desktop-medium(min) {
      padding: 42px 0;
    }
    @include desktop-large(min) {
      grid-template-columns: 670px auto;
    }

    &Wrapper {
      padding: 40px 0;
      border-radius: $footer-border-radius;
      background-color: $color-brand-primary;
    }

    &Social {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $color-white;
      gap: 40px;

      &Logo {
        width: 32px;
        height: 32px;
        @include tablet(min) {
          width: 40px;
          height: 40px;
        }
      }

      &Wrapper {
        display: flex;
        flex-direction: column;
        @include tablet(min) {
          align-items: center;
          flex-direction: row;
          justify-content: center;
          max-width: 304px;
          gap: 40px;
        }

        &Icons {
          display: flex;
          flex-direction: row;
          gap: 32px;
          justify-content: space-between;
        }
      }

      &Icon {
        --color1: #{$color-white};
        color: $color-white;

        &Site {
          width: 16px;
          height: 16px;
          @include tablet(min) {
            width: 24px;
            height: 24px;
          }
        }
      }

      &IconText {
        font-size: 12px;
        font-weight: $font-weight-medium-v2;
        line-height: 16px;
        display: inline-block;
        margin-left: 8px;
        text-decoration: none;
        color: $color-white !important;
        @include tablet(min) {
          display: none;
        }
      }

      &Line {
        width: 48px;
        margin-top: 24px;
        margin-bottom: 24px;
        border-top: 1px solid $color-white;
      }
    }

    &Buttons {
      display: grid;
      color: $color-white;
      grid-template-columns: 1fr;
      gap: 40px;
      @include tablet(min) {
        grid-template-columns: 202px auto;
      }
      @include desktop-medium(min) {
        grid-template-columns: 200px auto;
      }
      @include desktop-large(min) {
        grid-template-columns: 256px auto;
      }

      &Column {
        display: grid;
        grid-template-rows: auto auto 32px;
        gap: 12px;

        &Header {
          font-size: 16px;
          line-height: 20px;
          @include tablet(min) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        &Description {
          font-size: 12px;
          line-height: 16px;
          @include tablet(min) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &ButtonWrapper {
          width: min-content;
        }
      }
    }

    &Legal {
      font-size: 12px;
      font-weight: $font-weight-normal-v2;
      line-height: 16px;
      color: $color-white;

      &Desktop {
        display: none;
        @include desktop-small(min) {
          display: block;
        }
      }

      &Mobile {
        display: block;
        @include desktop-small(min) {
          display: none;
        }
      }
    }
  }
}
</style>
